import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/http';
import * as Sentry from '@sentry/react';

const initialState = {
  info: null,
  personalPlaces: [],
};

const slice = createSlice({
  initialState,
  name: 'place',
  reducers: {
    startLoading() {},
    hasError(state, action) {
      Sentry.captureException(action.payload);
      console.error(action.payload);
    },
    getPlaceInfoSuccess(state, action) {
      state.info = action.payload;
    },
    getPersonalPlaceSuccess(state, action) {
      state.personalPlaces = action.payload.places;
    },
  },
});

// Reducer
export const placeReducer = slice.reducer;

// ----------------------------------------------------------------------

export const getPlaceInfo = (placeId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await api.get(`/v1/places/${placeId}/populated`).then((res) => {
      dispatch(slice.actions.getPlaceInfoSuccess(res));
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};

export const getPersonalPlace = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await api.get(`/v1/statistics/places/personal`).then((res) => {
      dispatch(slice.actions.getPersonalPlaceSuccess(res));
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};
// ----------------------------------------------------------------------
