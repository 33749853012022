import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/http';
import * as Sentry from '@sentry/react';

const initialState = {
  list: [],
  loadedUsers: false,
  userSelected: null,
  userBookings: [],
  personalUserInfo: null,
};

const slice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    startLoading(state) {
      state.loadedUsers = true;
    },
    hasError(state, action) {
      Sentry.captureException(action.payload);
      console.error(action.payload);
      state.loadedUsers = false;
    },
    getUsersSuccess(state, action) {
      state.list = action.payload.users;
      state.loadedUsers = false;
    },
    setUser(state, action) {
      state.userSelected = action.payload;
    },
    getUserBookingSuccess(state, action) {
      state.userBookings = action.payload;
    },
    setPersonalUserInfoSuccess(state, action) {
      state.personalUserInfo = action.payload;
    },
  },
});

// Reducer
export const usersReducer = slice.reducer;

// ----------------------------------------------------------------------

export const getUsers = (query) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    query = {
      all: true,
      withMe: true,
      ...query,
    };
    await api.get('/v1/users', query).then((res) => {
      api.get('/v1/users', { ...query, limit: res.pagination.totalCount }).then((response) => {
        dispatch(slice.actions.getUsersSuccess(response));
      });
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};

export const setUserSelected = (user) => async (dispatch) => {
  dispatch(slice.actions.setUser(user));
};
export const userBookings = (userId, page, limit, type) => async (dispatch) => {
  let params = {};
  if (type) params = { ...params, type };
  if (page) params = { ...params, page };
  if (limit) params = { ...params, limit };
  dispatch(slice.actions.startLoading());
  try {
    await api.get(`/users/${userId}/bookings`, params).then((response) => {
      dispatch(slice.actions.getUserBookingSuccess(response));
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};

export const setPersonalUserInfo = (personalUserInfo) => async (dispatch) => {
  dispatch(slice.actions.setPersonalUserInfoSuccess(personalUserInfo));
};

// ----------------------------------------------------------------------
